<template>
  <div>
    <div class="category-box winAndplayCategory cardGame">
      <div v-if="loading" class="overlay-loader">
        <div class="loader-spin">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="catgory_head">
        <div class="title">
          <h4>
            <img
              src="/static/images/cards_ic .svg"
              class="titile_icon cardAnimate"
            />
            {{ $t("casinoGames") }}
          </h4>
        </div>
        <!-- <div class="categorytab">
          <img src="/static/images/right_arrow_ic.svg" />
        </div>-->
      </div>

      <ul>
        <!-- <li>
          <div class="gamegrid" @click="goToTeenpatti()">
            <div class="gameImgbox">
              <img src="../assets/img/3patti.jpg" />
              <div class="title-block">
                <div class="text-title">Teenpatti</div>
                <span class="palyer-counter">Play and Win</span>
              </div>
            </div>
          </div>
        </li> -->
        <!-- <li>
          <div class="gamegrid" @click="goToRoulette()">
            <div class="gameImgbox">
              <img src="../assets/img/roulette.jpg" />
              <div class="title-block">
                <div class="text-title">Roulette</div>
                <span class="palyer-counter">Play and Win</span>
              </div>
            </div>
          </div>
        </li> -->
        <li>
          <div class="gamegrid" @click="goToRummyPage()">
            <div class="gameImgbox">
              <img src="../assets/img/rumm91.jpg" />
              <div class="title-block">
                <div class="text-title">Rummy</div>
                <span class="palyer-counter">Play and Win</span>
              </div>
            </div>
          </div>
        </li>
        <!-- <li>
          <div class="gamegrid" @click="goToPoker()">
            <div class="gameImgbox">
              <img src="../assets/img/poker.png" />
              <div class="title-block">
                <div class="text-title">Poker</div>
                <span class="palyer-counter">Play and Win</span>
              </div>
            </div>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinAndPlayCategory",
  props: {},
  data() {
    return {
      spinnerLoading: false,
      xauthid: null,
      loading: false
    };
  },

  computed: {},

  methods: {
    goToTeenpatti() {
      this.loading = true;
      let token = this.$cookie.get("X-Auth-Id");
      if (token != null) {
        location.href = ` //dev3.money91.com/#/login?xauthid=${token}`;
      } else {
        token = window.location.hash.split("?xtoken=")[1].split("&", -1)[0];
        if (token != null) {
          location.href = ` //dev3.money91.com/#/login?xauthid=${token}`;
        }
      }
    },
    goToRummy() {
      this.loading = true;
      const __x = this.$cookie.get("X-Auth-Id");
      location.href = `https://rummy.services-money91.com/#/?xauthid=${__x}`;
    },

    goToRoulette() {
      this.loading = true;
      let token = this.$cookie.get("X-Auth-Token");
      if (token != null) {
        location.href = ` //roulette.services-money91.com/#/login?xtoken=${token}`;
      } else {
        token = window.location.hash.split("?xtoken=")[1].split("&", -1)[0];
        if (token != null) {
          location.href = `//roulette.services-money91.com/#/login?xtoken=${token}`;
        }
      }
    },
    // goToPoker() {
    //   let xAuthId = this.$cookie.get("X-Auth-Id");
    //   location.href = `//poker-ui.services-money91.com/#/login?xauthid=${xAuthId}`;
    // }
    goToRummyPage() {
      const _xauth = this.$cookie.get("X-Auth-Id");
      window.open(
        `https://rummy.khelo-91.com/#/?xauthid=${_xauth}&league=2`,
        "_blank"
      );
    }
  }
};
</script>
<style></style>
